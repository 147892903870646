/* eslint no-console:0 */

require("@rails/ujs").start()
require("turbolinks").start()

require("stylesheets/application.scss")

import "controllers"

import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

// Images (jpg, jpeg, png) are imported  here.
// This will copy all static images under `frontend/images` to the output
// folder and reference them with the image_pack_tag helper in views
// (e.g <%= image_pack_tag 'hero.png' %>) or the `imagePath` JavaScript helper
// below.

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require("jquery")

window.jQuery = $;
window.$ = $;

// require("js/theme")
// require("js/vendor.bundle.js")